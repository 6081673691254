import { ErrorDialog, ErrorPage, ErrorSubpage } from 'components/error'
import { Layout } from 'components/layout'
import { NavigationContainer } from 'components/page'
import { SignedInRoute } from 'components/signed-in'
import { adminRoutes } from 'const/admin-routes'
import { ownerRoutes } from 'const/owner-routes'
import NotFoundPage from 'pages/error/404'
import ForbiddenPage from 'pages/error/forbidden'
import WelcomePage from 'pages/welcome'
import { FC } from 'react'
import {
  Navigate,
  Route,
  createBrowserRouter,
  createRoutesFromElements,
  useSearchParams,
} from 'react-router-dom'
import { Root } from './root'

const RedirectWithSameSearch: FC<{ to: string; replace?: boolean }> = ({ to, replace = true }) => {
  const [searchParams] = useSearchParams()
  return <Navigate to={{ search: searchParams.toString(), pathname: to }} replace={replace} />
}

const UNIT_EDIT_PARTS = (
  <>
    <Route index element={<RedirectWithSameSearch to={adminRoutes.unitGeneral} />} />
    <Route path={adminRoutes.unitGeneral} lazy={() => import('admin-pages/units/edit/general')} />
    <Route path={adminRoutes.unitListing} lazy={() => import('admin-pages/units/edit/listing')} />
    <Route path={adminRoutes.unitPhotos} lazy={() => import('admin-pages/units/edit/photos')} />
    <Route
      path={adminRoutes.unitAmenities}
      lazy={() => import('admin-pages/units/edit/amenities')}
    />
    <Route
      path={adminRoutes.unitDescription}
      lazy={() => import('admin-pages/units/edit/description')}
    />
    <Route path={adminRoutes.unitLease} lazy={() => import('admin-pages/units/edit/lease')} />
    <Route path={adminRoutes.unitExtend} lazy={() => import('admin-pages/units/edit/extend')} />
    <Route path={adminRoutes.unitPublish} lazy={() => import('admin-pages/units/edit/publish')} />
  </>
)

const ADMIN_APPLICATION_LIST_ACTIONS = (
  <Route errorElement={<ErrorDialog />}>
    <Route
      path={adminRoutes.applicationsEdit}
      lazy={() => import('admin-pages/applications/edit')}
    />
    <Route
      path={adminRoutes.applicationsMove}
      lazy={() => import('admin-pages/applications/move')}
    />
    <Route
      path={adminRoutes.applicationsCancel}
      lazy={() => import('admin-pages/applications/cancel')}
    />
    <Route
      path={adminRoutes.applicationsDelete}
      lazy={() => import('admin-pages/applications/delete')}
    />
    <Route
      path={adminRoutes.applicationsReport}
      lazy={() => import('admin-pages/applications/report')}
    />
  </Route>
)

const ADMIN_TEMPLATE_LIST_ACTIONS = (
  <Route errorElement={<ErrorDialog />}>
    <Route path={adminRoutes.templatesEdit} lazy={() => import(`./admin-pages/templates/edit`)} />
    <Route path={adminRoutes.templatesClone} lazy={() => import(`./admin-pages/templates/clone`)} />
    <Route
      path={adminRoutes.templatesDelete}
      lazy={() => import(`./admin-pages/templates/delete`)}
    />
    <Route
      path={adminRoutes.templatesViewPdf}
      lazy={() => import(`./admin-pages/templates/view-pdf`)}
    />
  </Route>
)
const ADMIN_LEASE_LIST_ACTIONS = (
  <Route errorElement={<ErrorDialog />}>
    <Route
      path={adminRoutes.leaseContractView}
      lazy={() => import('admin-pages/leases/contract-view')}
    />
    <Route path={adminRoutes.leaseRenew} lazy={() => import('admin-pages/leases/renew')} />
    <Route path={adminRoutes.leaseDelete} lazy={() => import('admin-pages/leases/delete')} />
  </Route>
)

const ADMIN_LEASE_DETAILS_ACTIONS = (
  <Route errorElement={<ErrorDialog />}>
    <Route
      path={adminRoutes.leaseDetailsPhotoIdView}
      lazy={() => import('admin-pages/lease/overview/photo-id-view')}
    />
    <Route
      path={adminRoutes.leaseDetailsContractView}
      lazy={() => import('admin-pages/lease/overview/contract-view')}
    />
    <Route
      path={adminRoutes.leaseDetailsLock}
      lazy={() => import('admin-pages/lease/overview/lock')}
    />
    <Route
      path={adminRoutes.leaseDetailsRegenerate}
      lazy={() => import('admin-pages/lease/overview/regenerate')}
    />
    <Route
      path={adminRoutes.leaseDetailsDraftPreview}
      lazy={() => import('admin-pages/lease/overview/draft-preview')}
    />
    <Route
      path={adminRoutes.leaseDetailsDelete}
      lazy={() => import('admin-pages/lease/overview/delete')}
    />
    <Route
      path={adminRoutes.leaseDetailsRenew}
      lazy={() => import('admin-pages/lease/overview/renew')}
    />
    <Route
      path={adminRoutes.leaseDetailsSign}
      lazy={() => import('admin-pages/lease/overview/sign')}
    />
  </Route>
)

const ADMIN_PAGES = (
  <>
    <Route index element={<Navigate to={adminRoutes.dashboard} replace />} />
    <Route path={adminRoutes.dashboard} lazy={() => import('admin-pages/dashboard')} />
    <Route path={adminRoutes.invitations} lazy={() => import('admin-pages/invitation')} />
    <Route path={adminRoutes.units} lazy={() => import('admin-pages/units')}>
      <Route path={adminRoutes.unitCreate} lazy={() => import('admin-pages/units/create')} />
      <Route path={adminRoutes.unitsEdit} lazy={() => import('admin-pages/units/edit')}>
        {UNIT_EDIT_PARTS}
      </Route>
      <Route path={adminRoutes.unitsImportRM} lazy={() => import('admin-pages/units/import-rm')} />
      <Route
        path={adminRoutes.unitsImportURL}
        lazy={() => import('admin-pages/units/import-url')}
      />
      <Route path={adminRoutes.unitsDelete} lazy={() => import('admin-pages/units/delete')} />
    </Route>
    <Route path={adminRoutes.unitDetails} lazy={() => import('admin-pages/unit')}>
      <Route errorElement={<ErrorSubpage />}>
        <Route index element={<Navigate to={adminRoutes.unitDetailsDetails} replace />} />
        <Route path={adminRoutes.unitDetailsShare} lazy={() => import('admin-pages/unit/share')} />
        <Route
          path={adminRoutes.unitDetailsDetails}
          lazy={() => import('./admin-pages/unit/overview')}
        >
          <Route
            path={adminRoutes.unitDetailsDelete}
            lazy={() => import('admin-pages/unit/overview/delete')}
          />
          <Route
            path={adminRoutes.unitDetailsEdit}
            lazy={() => import('admin-pages/unit/overview/edit')}
          >
            {UNIT_EDIT_PARTS}
          </Route>
          <Route
            path={adminRoutes.unitDetailsImportRM}
            lazy={() => import('admin-pages/unit/overview/import-rm')}
          />
          <Route
            path={adminRoutes.unitDetailsImportURL}
            lazy={() => import('admin-pages/unit/overview/import-url')}
          />
          <Route
            path={adminRoutes.unitDetailsFindLocalion}
            lazy={() => import('admin-pages/unit/overview/find-location')}
          />
          <Route
            path={adminRoutes.unitDetailsLocalion}
            lazy={() => import('admin-pages/unit/overview/location')}
          />
          <Route
            path={adminRoutes.unitDetailsAddPhoto}
            lazy={() => import('admin-pages/unit/overview/add-photo-dialog')}
          />
          <Route
            path={adminRoutes.unitDetailsViewPhoto}
            lazy={() => import('admin-pages/unit/overview/view-photo')}
          />
          <Route
            path={adminRoutes.unitDetailsDeletePhoto}
            lazy={() => import('admin-pages/unit/overview/delete-photo-dialog')}
          />
        </Route>
        <Route path={adminRoutes.unitDetailsAgents} lazy={() => import('admin-pages/unit/agents')}>
          <Route
            path={adminRoutes.unitDetailsAgentsManage}
            lazy={() => import('admin-pages/unit/agents/manage')}
          />
        </Route>
        <Route
          path={adminRoutes.unitApplications}
          lazy={() => import('./admin-pages/unit/applications')}
        >
          {ADMIN_APPLICATION_LIST_ACTIONS}
        </Route>
        <Route
          path={adminRoutes.unitDetailsLeases}
          lazy={() => import('./admin-pages/unit/leases')}
        >
          {ADMIN_LEASE_LIST_ACTIONS}
        </Route>
        <Route
          path={adminRoutes.unitDetailsAuction}
          lazy={() => import('./admin-pages/unit/auction')}
        >
          <Route
            path={adminRoutes.unitDetailsAuctionEdit}
            lazy={() => import('./admin-pages/unit/auction/edit')}
          />
          <Route
            path={adminRoutes.unitDetailsAuctionClose}
            lazy={() => import('./admin-pages/unit/auction/close-auction')}
          />
          <Route
            path={adminRoutes.unitDetailsAuctionReopen}
            lazy={() => import('./admin-pages/unit/auction/reopen-auction')}
          />
          <Route
            path={adminRoutes.unitDetailsAuctionArchive}
            lazy={() => import('./admin-pages/unit/auction/archive')}
          />
          <Route
            path={adminRoutes.unitDetailsAuctionDelete}
            lazy={() => import('./admin-pages/unit/auction/delete')}
          />
          <Route
            path={adminRoutes.unitDetailsAuctionCancelApplication}
            lazy={() => import('./admin-pages/unit/auction/cancel-application')}
          />
        </Route>
        <Route
          path={adminRoutes.unitInvitedUsers}
          lazy={() => import('./admin-pages/unit/invited-users')}
        />
        <Route
          path={adminRoutes.unitDetailsAuditLog}
          lazy={() => import('./admin-pages/unit/audit')}
        />
      </Route>
    </Route>
    <Route path={adminRoutes.properties} lazy={() => import('admin-pages/properties')}>
      <Route
        path={adminRoutes.propertiesCreate}
        lazy={() => import('admin-pages/properties/create')}
      />
      <Route path={adminRoutes.propertiesEdit} lazy={() => import('admin-pages/properties/edit')} />
      <Route
        path={adminRoutes.propertiesDelete}
        lazy={() => import('admin-pages/properties/delete')}
      />
    </Route>
    <Route path={adminRoutes.property} lazy={() => import('admin-pages/property')}>
      <Route errorElement={<ErrorSubpage />}>
        <Route index element={<Navigate to={adminRoutes.propertyOverview} replace />} />
        <Route
          path={adminRoutes.propertyOverview}
          lazy={() => import('admin-pages/property/overview')}
        >
          <Route
            path={adminRoutes.propertyOverviewLocation}
            lazy={() => import('admin-pages/property/overview/location')}
          />
          <Route
            path={adminRoutes.propertyOverviewEdit}
            lazy={() => import('admin-pages/property/overview/edit')}
          />
          <Route
            path={adminRoutes.propertyOverviewDelete}
            lazy={() => import('admin-pages/property/overview/delete')}
          />
          <Route
            path={adminRoutes.propertyOverviewFindLocation}
            lazy={() => import('admin-pages/property/overview/find-location')}
          />
        </Route>
        <Route path={adminRoutes.propertyUnits} lazy={() => import('admin-pages/property/units')}>
          <Route
            path={adminRoutes.propertyDetailsUnitEdit}
            lazy={() => import('admin-pages/property/units/edit')}
          >
            {UNIT_EDIT_PARTS}
          </Route>
        </Route>
        <Route
          path={adminRoutes.propertyAuditLog}
          lazy={() => import('admin-pages/property/audit')}
        />
      </Route>
    </Route>
    <Route path={adminRoutes.payments} lazy={() => import('admin-pages/ledger')}>
      <Route path={adminRoutes.paymentsEdit} lazy={() => import('admin-pages/ledger/edit')} />
      <Route path={adminRoutes.paymentsDelete} lazy={() => import('admin-pages/ledger/delete')} />
    </Route>
    <Route path={adminRoutes.applications} lazy={() => import('admin-pages/applications')}>
      {ADMIN_APPLICATION_LIST_ACTIONS}
    </Route>
    <Route path={adminRoutes.application} lazy={() => import('admin-pages/application')}>
      <Route errorElement={<ErrorSubpage />}>
        <Route index element={<Navigate to={adminRoutes.applicationOverview} replace />} />
        <Route
          path={adminRoutes.applicationOverview}
          lazy={() => import('admin-pages/application/overview')}
        >
          <Route
            path={adminRoutes.applicationOverviewMove}
            lazy={() => import('admin-pages/application/overview/move')}
          />
          <Route
            path={adminRoutes.applicationOverviewEdit}
            lazy={() => import('admin-pages/application/overview/edit')}
          />
          <Route
            path={adminRoutes.applicationOverviewDelete}
            lazy={() => import('admin-pages/application/overview/delete')}
          />
          <Route
            path={adminRoutes.applicationOverviewCancel}
            lazy={() => import('admin-pages/application/overview/cancel')}
          />
          <Route
            path={adminRoutes.applicationOverviewLeaseDraftCreate}
            lazy={() => import('admin-pages/application/overview/draft-create')}
          />
          <Route
            path={adminRoutes.applicationOverviewLeaseDraftPreview}
            lazy={() => import('admin-pages/application/overview/draft-preview')}
          />
          <Route
            path={adminRoutes.applicationReport}
            lazy={() => import('admin-pages/application/overview/report')}
          />
          <Route
            path={adminRoutes.applicationUserAdd}
            lazy={() => import('admin-pages/application/overview/user-add')}
          />
          <Route
            path={adminRoutes.applicationUserDelete}
            lazy={() => import('admin-pages/application/overview/user-remove')}
          />
        </Route>
        <Route
          path={adminRoutes.applicationChat}
          lazy={() => import('admin-pages/application/chat')}
        >
          <Route
            path={adminRoutes.chatListCreate}
            lazy={() => import('admin-pages/application/chat/create')}
          />
          <Route
            path={adminRoutes.chatManageSupport}
            lazy={() => import('admin-pages/application/chat/manage-users')}
          />
        </Route>
      </Route>
    </Route>
    <Route path={adminRoutes.users} lazy={() => import('admin-pages/users')}>
      <Route path={adminRoutes.userCreate} lazy={() => import('admin-pages/users/create')} />
      <Route path={adminRoutes.usersEdit} lazy={() => import('admin-pages/users/edit')} />
    </Route>
    <Route path={adminRoutes.userDetails} lazy={() => import('admin-pages/user')}>
      <Route errorElement={<ErrorSubpage />}>
        <Route index element={<Navigate to={adminRoutes.userDetailsOverview} replace />} />
        <Route
          path={adminRoutes.userDetailsOverview}
          lazy={() => import('admin-pages/user/overview')}
        >
          <Route
            path={adminRoutes.userDetailsEdit}
            lazy={() => import('admin-pages/user/overview/edit')}
          />
          <Route
            path={adminRoutes.userDetailsGuarantorSet}
            lazy={() => import('admin-pages/user/overview/guarantor-set')}
          />
          <Route
            path={adminRoutes.userDetailsGuarantorReset}
            lazy={() => import('admin-pages/user/overview/guarantor-reset')}
          />
          <Route
            path={adminRoutes.userDetailsCosignerSet}
            lazy={() => import('admin-pages/user/overview/cosigner-set')}
          />
          <Route
            path={adminRoutes.userDetailsCosignerReset}
            lazy={() => import('admin-pages/user/overview/cosigner-reset')}
          />
          <Route
            path={adminRoutes.userDetailsViewPhotoId}
            lazy={() => import('admin-pages/user/overview/view-photo-id')}
          />
          <Route
            path={adminRoutes.userDetailsCreditScoreDownload}
            lazy={() => import('admin-pages/user/overview/credit-score-download-dialog')}
          />
          <Route
            path={adminRoutes.userDetailsCreditScoreCancel}
            lazy={() => import('admin-pages/user/overview/credit-score-cancel-dialog')}
          />
          <Route
            path={adminRoutes.userDetailsCreditScoreSubmit}
            lazy={() => import('admin-pages/user/overview/credit-score-start-dialog')}
          />
        </Route>
        <Route path={adminRoutes.userDetailsOwners} lazy={() => import('admin-pages/user/owners')}>
          <Route
            path={adminRoutes.userDetailsOwnersRemove}
            lazy={() => import('admin-pages/user/owners/remove')}
          />
        </Route>
        <Route
          path={adminRoutes.userDetailsInvitedUsers}
          lazy={() => import('admin-pages/user/invited-users')}
        />
        <Route
          path={adminRoutes.userAccountScores}
          lazy={() => import('admin-pages/user/account-scores')}
        >
          <Route
            path={adminRoutes.userAccountScoreData}
            lazy={() => import('admin-pages/user/account-scores/data')}
          />
          <Route
            path={adminRoutes.userAccountScoreModels}
            lazy={() => import('admin-pages/user/account-scores/models')}
          />
          <Route
            path={adminRoutes.userAccountScoreTransactions}
            lazy={() => import('admin-pages/user/account-scores/transactions')}
          />
        </Route>

        <Route
          path={adminRoutes.userDetailsLinkedAccounts}
          lazy={() => import('admin-pages/user/linked-accounts')}
        >
          <Route
            path={adminRoutes.userDetailsGuarantorSet}
            lazy={() => import('admin-pages/user/linked-accounts/guarantor-set')}
          />
          <Route
            path={adminRoutes.userDetailsGuarantorReset}
            lazy={() => import('admin-pages/user/linked-accounts/guarantor-reset')}
          />
          <Route
            path={adminRoutes.userDetailsCosignerSet}
            lazy={() => import('admin-pages/user/linked-accounts/cosigner-set')}
          />
          <Route
            path={adminRoutes.userDetailsCosignerReset}
            lazy={() => import('admin-pages/user/linked-accounts/cosigner-reset')}
          />
        </Route>
        <Route
          path={adminRoutes.userDetailsAgentUnits}
          lazy={() => import('admin-pages/user/agent-units')}
        >
          <Route
            path={adminRoutes.userDetailsAgentUnitsEdit}
            lazy={() => import('admin-pages/user/agent-units/edit')}
          >
            {UNIT_EDIT_PARTS}
          </Route>
        </Route>
        <Route
          path={adminRoutes.userDetailsApplications}
          lazy={() => import('admin-pages/user/applications')}
        >
          {ADMIN_APPLICATION_LIST_ACTIONS}
        </Route>
        <Route path={adminRoutes.userDetailsLeases} lazy={() => import('admin-pages/user/leases')}>
          {ADMIN_LEASE_LIST_ACTIONS}
        </Route>
        <Route
          path={adminRoutes.userDetailsDocuments}
          lazy={() => import('admin-pages/user/documents')}
        >
          <Route
            path={adminRoutes.userDetailsDocumentsUpload}
            lazy={() => import('admin-pages/user/documents/upload')}
          />
          <Route
            path={adminRoutes.userDetailsDocumentsView}
            lazy={() => import('admin-pages/user/documents/view-pdf')}
          />
          <Route
            path={adminRoutes.userDetailsDocumentsEdit}
            lazy={() => import('admin-pages/user/documents/edit')}
          />
          <Route
            path={adminRoutes.userDetailsDocumentsDelete}
            lazy={() => import('admin-pages/user/documents/delete')}
          />
          <Route
            path={adminRoutes.userDetailsDocumentsRawContentPages}
            lazy={() => import('admin-pages/user/documents/raw-content-pages')}
          />
          <Route
            path={adminRoutes.userDetailsDocumentsRawCombined}
            lazy={() => import('admin-pages/user/documents/raw-combined')}
          />
        </Route>
        <Route path={adminRoutes.userDetailsChats} lazy={() => import('admin-pages/user/chats')}>
          <Route
            path={adminRoutes.userDetailsChatsCreate}
            lazy={() => import('admin-pages/user/chats/create')}
          />
          <Route
            path={adminRoutes.userDetailsChatsView}
            lazy={() => import('admin-pages/user/chats/view')}
          />
          <Route
            path={adminRoutes.userDetailsChatsDelete}
            lazy={() => import('admin-pages/user/chats/delete')}
          />
        </Route>
        <Route path={adminRoutes.userDetailsAudit} lazy={() => import('admin-pages/user/audit')} />
      </Route>
    </Route>
    <Route path={adminRoutes.owners} lazy={() => import('admin-pages/owners')}>
      <Route path={adminRoutes.ownerCreate} lazy={() => import('admin-pages/owners/create')} />
      <Route path={adminRoutes.ownerEdit} lazy={() => import('admin-pages/owners/edit')} />
    </Route>
    <Route path={adminRoutes.ownerDetails} lazy={() => import('admin-pages/owner')}>
      <Route errorElement={<ErrorSubpage />}>
        <Route index element={<Navigate to={adminRoutes.ownerDetailsOverview} replace />} />
        <Route
          path={adminRoutes.ownerDetailsOverview}
          lazy={() => import('admin-pages/owner/overview')}
        >
          <Route
            path={adminRoutes.ownerDetailsOverviewEdit}
            lazy={() => import('admin-pages/owner/overview/edit')}
          />
        </Route>
        <Route path={adminRoutes.ownerDetailsUnits} lazy={() => import('admin-pages/owner/units')}>
          <Route
            path={adminRoutes.ownerDetailsUnitsEdit}
            lazy={() => import('admin-pages/owner/units/edit')}
          >
            {UNIT_EDIT_PARTS}
          </Route>
        </Route>
        <Route
          path={adminRoutes.ownerDetailsProperties}
          lazy={() => import('admin-pages/owner/properties')}
        >
          <Route
            path={adminRoutes.ownerDetailsPropertiesCreate}
            lazy={() => import('admin-pages/owner/properties/create')}
          />
          <Route
            path={adminRoutes.ownerDetailsPropertiesEdit}
            lazy={() => import('admin-pages/owner/properties/edit')}
          />
          <Route
            path={adminRoutes.ownerDetailsPropertiesDelete}
            lazy={() => import('admin-pages/owner/properties/delete')}
          />
        </Route>
        <Route path={adminRoutes.ownerDetailsUsers} lazy={() => import('admin-pages/owner/users')}>
          <Route
            path={adminRoutes.ownerDetailsUsersManage}
            lazy={() => import('admin-pages/owner/users/manage')}
          />
        </Route>
        <Route
          path={adminRoutes.ownerDetailsTemplates}
          lazy={() => import('admin-pages/owner/templates')}
        >
          <Route
            path={adminRoutes.templatesCreate}
            lazy={() => import('admin-pages/owner/templates/create')}
          />
          {ADMIN_TEMPLATE_LIST_ACTIONS}
        </Route>
        <Route
          path={adminRoutes.ownerDetailsAccounts}
          lazy={() => import('admin-pages/owner/accounts')}
        >
          <Route
            path={adminRoutes.ownerDetailsAccountsCreate}
            lazy={() => import('admin-pages/owner/accounts/create')}
          />
          <Route
            path={adminRoutes.ownerDetailsAccountsEdit}
            lazy={() => import('admin-pages/owner/accounts/edit')}
          />
          <Route
            path={adminRoutes.ownerDetailsAccountsDelete}
            lazy={() => import('admin-pages/owner/accounts/delete')}
          />
        </Route>
      </Route>
    </Route>
    <Route path={adminRoutes.propertyManagers} lazy={() => import('admin-pages/property-managers')}>
      <Route
        path={adminRoutes.propertyManagersCreate}
        lazy={() => import('admin-pages/property-managers/create')}
      />
      <Route
        path={adminRoutes.propertyManagersEdit}
        lazy={() => import('admin-pages/property-managers/edit')}
      />
    </Route>
    <Route path={adminRoutes.propertyManager} lazy={() => import('admin-pages/property-manager')}>
      <Route errorElement={<ErrorSubpage />}>
        <Route index element={<Navigate to={adminRoutes.propertyManagerOverview} replace />} />
        <Route
          path={adminRoutes.propertyManagerOverview}
          lazy={() => import('admin-pages/property-manager/overview')}
        >
          <Route
            path={adminRoutes.propertyManagerOverviewEdit}
            lazy={() => import('admin-pages/property-manager/overview/edit')}
          />
        </Route>
        <Route
          path={adminRoutes.propertyManagerOwners}
          lazy={() => import('admin-pages/property-manager/owners')}
        >
          <Route
            path={adminRoutes.propertyManagerOwnersAdd}
            lazy={() => import('admin-pages/property-manager/owners/add')}
          />
          <Route
            path={adminRoutes.propertyManagerOwnersEdit}
            lazy={() => import('admin-pages/property-manager/owners/edit')}
          />
        </Route>
        <Route
          path={adminRoutes.propertyManagerUsers}
          lazy={() => import('admin-pages/property-manager/users')}
        >
          <Route
            path={adminRoutes.propertyManagerUsersLinkOwners}
            lazy={() => import('admin-pages/property-manager/users/link-owners')}
          />
          <Route
            path={adminRoutes.propertyManagerUsersUnlink}
            lazy={() => import('admin-pages/property-manager/users/unlink')}
          />
          <Route
            path={adminRoutes.propertyManagerUsersAdd}
            lazy={() => import('admin-pages/property-manager/users/add')}
          />
        </Route>
        <Route
          path={adminRoutes.propertyManagerAccounts}
          lazy={() => import('admin-pages/property-manager/accounts')}
        />
      </Route>
    </Route>
    <Route path={adminRoutes.leases} lazy={() => import('admin-pages/leases')}>
      {ADMIN_LEASE_LIST_ACTIONS}
    </Route>
    <Route path={adminRoutes.leaseDetails} lazy={() => import('admin-pages/lease')}>
      <Route errorElement={<ErrorSubpage />}>
        <Route index element={<Navigate to={adminRoutes.leaseDetailsOverview} replace />} />
        <Route
          path={adminRoutes.leaseDetailsOverview}
          lazy={() => import('admin-pages/lease/overview')}
        >
          {ADMIN_LEASE_DETAILS_ACTIONS}
        </Route>
        <Route
          path={adminRoutes.leaseDetailsChecklist}
          lazy={() => import('admin-pages/lease/checklist')}
        >
          <Route errorElement={<ErrorDialog />}>
            <Route
              path={adminRoutes.leaseDetailsEditChecklist}
              lazy={() => import('admin-pages/lease/checklist/edit-checklist-item')}
            />
            {ADMIN_LEASE_DETAILS_ACTIONS}
          </Route>
        </Route>
        <Route
          path={adminRoutes.leaseDetailsLedger}
          lazy={() => import('admin-pages/lease/ledger')}
        >
          <Route
            path={adminRoutes.leaseDetailsLedgerCreateCharge}
            lazy={() => import('admin-pages/lease/ledger/create-charge')}
          />
          <Route
            path={adminRoutes.leaseDetailsLedgerCreatePayment}
            lazy={() => import('admin-pages/lease/ledger/create-payment')}
          />
          <Route
            path={adminRoutes.leaseDetailsLedgerEdit}
            lazy={() => import('admin-pages/lease/ledger/edit')}
          />
          <Route
            path={adminRoutes.leaseDetailsLedgerDelete}
            lazy={() => import('admin-pages/lease/ledger/delete')}
          />
        </Route>
        <Route
          path={adminRoutes.leaseDetailsInvoice}
          lazy={() => import('admin-pages/lease/invoice')}
        >
          <Route
            path={adminRoutes.leaseDetailsInvoiceEdit}
            lazy={() => import('admin-pages/lease/invoice/edit')}
          />
          <Route
            path={adminRoutes.leaseDetailsInvoiceDelete}
            lazy={() => import('admin-pages/lease/invoice/delete')}
          />
        </Route>
        <Route
          path={adminRoutes.leaseDetailsTemplates}
          lazy={() => import('admin-pages/lease/templates')}
        >
          {ADMIN_TEMPLATE_LIST_ACTIONS}
        </Route>
        <Route
          path={adminRoutes.leaseDetailsAudit}
          lazy={() => import('admin-pages/lease/audit')}
        />
      </Route>
    </Route>
    <Route path={adminRoutes.plaidRequests} lazy={() => import('admin-pages/plaid-requests')}>
      <Route
        path={adminRoutes.plaidRequestDetails}
        lazy={() => import('admin-pages/plaid-requests/details-dialog')}
      />
    </Route>
    <Route path={adminRoutes.templates} lazy={() => import(`./admin-pages/templates`)}>
      <Route
        path={adminRoutes.templatesCreate}
        lazy={() => import(`./admin-pages/templates/create`)}
      />
      {ADMIN_TEMPLATE_LIST_ACTIONS}
    </Route>
    <Route path={adminRoutes.templateDetails} lazy={() => import(`./admin-pages/template`)}>
      <Route errorElement={<ErrorSubpage />}>
        <Route index element={<Navigate to={adminRoutes.templateDetailsOverview} replace />} />
        <Route
          path={adminRoutes.templateDetailsOverview}
          lazy={() => import(`./admin-pages/template/overview`)}
        >
          <Route
            path={adminRoutes.templateDetailsOverviewClone}
            lazy={() => import(`./admin-pages/template/overview/clone`)}
          />
          <Route
            path={adminRoutes.templateDetailsOverviewEdit}
            lazy={() => import(`./admin-pages/template/overview/edit`)}
          />
          <Route
            path={adminRoutes.templateDetailsOverviewDelete}
            lazy={() => import(`./admin-pages/template/overview/delete`)}
          />
          <Route
            path={adminRoutes.templateDetailsOverviewViewPdf}
            lazy={() => import(`./admin-pages/template/overview/view-pdf`)}
          />
        </Route>
        <Route
          path={adminRoutes.templateDetailsEditor}
          lazy={() => import(`./admin-pages/template/editor`)}
        />
      </Route>
    </Route>
    <Route path={adminRoutes.support} lazy={() => import('admin-pages/support')}>
      <Route path={adminRoutes.supportChat} lazy={() => import('admin-pages/support/chat')}>
        <Route
          path={adminRoutes.chatArchive}
          lazy={() => import('admin-pages/support/chat/archive')}
        />
        <Route path={adminRoutes.chatEdit} lazy={() => import('admin-pages/support/chat/edit')} />
        <Route
          path={adminRoutes.chatDelete}
          lazy={() => import('admin-pages/support/chat/delete')}
        />
        <Route path={adminRoutes.chatFile} lazy={() => import('admin-pages/support/chat/file')} />
        <Route
          path={adminRoutes.chatManageSupport}
          lazy={() => import('admin-pages/support/chat/manage-support')}
        />
      </Route>
    </Route>
  </>
)

const OWNER_APPLICATION_LIST_ACTIONS = (
  <Route errorElement={<ErrorDialog />}>
    <Route
      path={ownerRoutes.applicationsEdit}
      lazy={() => import('owner-pages/applications/edit')}
    />
    <Route
      path={ownerRoutes.applicationsMove}
      lazy={() => import('owner-pages/applications/move')}
    />
    <Route
      path={ownerRoutes.applicationsCancel}
      lazy={() => import('owner-pages/applications/cancel')}
    />
    <Route
      path={ownerRoutes.applicationsReport}
      lazy={() => import('owner-pages/applications/report')}
    />
  </Route>
)
const OWNER_LEASE_LIST_ACTIONS = (
  <Route errorElement={<ErrorDialog />}>
    <Route
      path={ownerRoutes.leaseContractView}
      lazy={() => import('owner-pages/leases/contract-view')}
    />
    <Route path={ownerRoutes.leaseRenew} lazy={() => import('owner-pages/leases/renew')} />
    <Route path={ownerRoutes.leasesDelete} lazy={() => import('owner-pages/leases/delete')} />
  </Route>
)

const OWNER_LEASE_DETAILS_ACTIONS = (
  <Route errorElement={<ErrorDialog />}>
    <Route
      path={ownerRoutes.leaseDetailsPhotoIdView}
      lazy={() => import('owner-pages/lease/overview/photo-id-view/')}
    />
    <Route
      path={ownerRoutes.leaseDetailsContractView}
      lazy={() => import('owner-pages/lease/overview/contract-view')}
    />
    <Route
      path={ownerRoutes.leaseDetailsLock}
      lazy={() => import('owner-pages/lease/overview/lock')}
    />
    <Route
      path={ownerRoutes.leaseDetailsRegenerate}
      lazy={() => import('owner-pages/lease/overview/regenerate')}
    />
    <Route
      path={ownerRoutes.leaseDetailsDraftPreview}
      lazy={() => import('owner-pages/lease/overview/draft-preview')}
    />
    <Route
      path={ownerRoutes.leaseDetailsDelete}
      lazy={() => import('owner-pages/lease/overview/delete')}
    />
    <Route
      path={ownerRoutes.leaseDetailsRenew}
      lazy={() => import('owner-pages/lease/overview/renew')}
    />
    <Route
      path={ownerRoutes.leaseDetailsSign}
      lazy={() => import('owner-pages/lease/overview/sign')}
    />
  </Route>
)

const OWNER_PAGES = (
  <>
    <Route index element={<Navigate to={ownerRoutes.dashboard} replace />} />
    <Route path={ownerRoutes.dashboard} lazy={() => import(`./owner-pages/dashboard`)} />
    <Route path={ownerRoutes.payments} lazy={() => import(`./owner-pages/ledger`)} />
    <Route path={ownerRoutes.paymentAccounts} lazy={() => import(`./owner-pages/payment-accounts`)}>
      <Route
        path={ownerRoutes.paymentAccountsCreate}
        lazy={() => import('./owner-pages/payment-accounts/create')}
      />
      <Route
        path={ownerRoutes.paymentAccountsEdit}
        lazy={() => import('./owner-pages/payment-accounts/edit')}
      />
      <Route
        path={ownerRoutes.paymentAccountsDelete}
        lazy={() => import('./owner-pages/payment-accounts/delete')}
      />
    </Route>
    <Route path={ownerRoutes.units} lazy={() => import(`./owner-pages/units`)}>
      <Route path={ownerRoutes.unitCreate} lazy={() => import('owner-pages/units/create')} />
      <Route path={ownerRoutes.unitsEdit} lazy={() => import('owner-pages/units/edit')}>
        {UNIT_EDIT_PARTS}
      </Route>
      <Route path={ownerRoutes.unitsImport} lazy={() => import('owner-pages/units/import')} />
    </Route>
    <Route path={ownerRoutes.unitDetails} lazy={() => import(`./owner-pages/unit`)}>
      <Route errorElement={<ErrorSubpage />}>
        <Route index element={<Navigate to={ownerRoutes.unitDetailsDetails} replace />} />
        <Route path={ownerRoutes.unitDetailsShare} lazy={() => import('owner-pages/unit/share')} />
        <Route
          path={ownerRoutes.unitDetailsDetails}
          lazy={() => import('./owner-pages/unit/overview')}
        >
          <Route
            path={ownerRoutes.unitDetailsDelete}
            lazy={() => import('owner-pages/unit/overview/delete')}
          />
          <Route
            path={ownerRoutes.unitDetailsEdit}
            lazy={() => import('owner-pages/unit/overview/edit')}
          >
            {UNIT_EDIT_PARTS}
          </Route>
          <Route
            path={ownerRoutes.unitDetailsImport}
            lazy={() => import('owner-pages/unit/overview/import')}
          />
          <Route
            path={ownerRoutes.unitDetailsLocation}
            lazy={() => import('owner-pages/unit/overview/location')}
          />
          <Route
            path={ownerRoutes.unitDetailsFindLocalion}
            lazy={() => import('owner-pages/unit/overview/find-location')}
          />
          <Route
            path={ownerRoutes.unitDetailsAddPhoto}
            lazy={() => import('owner-pages/unit/overview/add-photo-dialog')}
          />
          <Route
            path={ownerRoutes.unitDetailsViewPhoto}
            lazy={() => import('owner-pages/unit/overview/view-photo')}
          />
          <Route
            path={ownerRoutes.unitDetailsDeletePhoto}
            lazy={() => import('owner-pages/unit/overview/delete-photo-dialog')}
          />
        </Route>
        <Route path={ownerRoutes.unitDetailsAgents} lazy={() => import('owner-pages/unit/agents')}>
          <Route
            path={ownerRoutes.unitDetailsAgentsManage}
            lazy={() => import('owner-pages/unit/agents/manage')}
          />
        </Route>
        <Route
          path={ownerRoutes.unitInvitedUsers}
          lazy={() => import('./owner-pages/unit/invited-users')}
        />
        <Route
          path={ownerRoutes.unitApplications}
          lazy={() => import('./owner-pages/unit/applications')}
        >
          {OWNER_APPLICATION_LIST_ACTIONS}
        </Route>
        <Route
          path={ownerRoutes.unitDetailsLeases}
          lazy={() => import('./owner-pages/unit/leases')}
        >
          {OWNER_LEASE_LIST_ACTIONS}
        </Route>
        <Route
          path={ownerRoutes.unitDetailsAuction}
          lazy={() => import('./owner-pages/unit/auction')}
        >
          <Route
            path={ownerRoutes.unitDetailsAuctionEdit}
            lazy={() => import('./owner-pages/unit/auction/edit-auction')}
          />
          <Route
            path={ownerRoutes.unitDetailsAuctionClose}
            lazy={() => import('./owner-pages/unit/auction/close-auction')}
          />
          <Route
            path={ownerRoutes.unitDetailsAuctionReopen}
            lazy={() => import('./owner-pages/unit/auction/reopen-auction')}
          />
          <Route
            path={ownerRoutes.unitDetailsAuctionArchive}
            lazy={() => import('./owner-pages/unit/auction/archive')}
          />
          <Route
            path={ownerRoutes.unitDetailsAuctionDelete}
            lazy={() => import('./owner-pages/unit/auction/delete-auction')}
          />
        </Route>
        <Route
          path={ownerRoutes.unitDetailsAuditLog}
          lazy={() => import('./owner-pages/unit/audit')}
        />
      </Route>
    </Route>
    <Route path={ownerRoutes.properties} lazy={() => import(`./owner-pages/properties`)}>
      <Route
        path={ownerRoutes.propertiesCreate}
        lazy={() => import('owner-pages/properties/create')}
      />
      <Route path={ownerRoutes.propertiesEdit} lazy={() => import('owner-pages/properties/edit')} />
    </Route>
    <Route path={ownerRoutes.property} lazy={() => import(`./owner-pages/property`)}>
      <Route errorElement={<ErrorSubpage />}>
        <Route index element={<Navigate to={ownerRoutes.propertyOverview} replace />} />
        <Route
          path={ownerRoutes.propertyOverview}
          lazy={() => import('owner-pages/property/overview')}
        >
          <Route
            path={ownerRoutes.propertyOverviewLocation}
            lazy={() => import('owner-pages/property/overview/location')}
          />
          <Route
            path={ownerRoutes.propertyOverviewEdit}
            lazy={() => import('owner-pages/property/overview/edit')}
          />
          <Route
            path={ownerRoutes.propertyOverviewFindLocation}
            lazy={() => import('owner-pages/property/overview/find-location')}
          />
        </Route>
        <Route path={ownerRoutes.propertyUnits} lazy={() => import('owner-pages/property/units')}>
          <Route
            path={ownerRoutes.propertyDetailsUnitEdit}
            lazy={() => import('owner-pages/property/units/edit')}
          >
            {UNIT_EDIT_PARTS}
          </Route>
        </Route>
        <Route
          path={ownerRoutes.propertyAuditLog}
          lazy={() => import('owner-pages/property/audit')}
        />
      </Route>
    </Route>
    <Route path={ownerRoutes.invitations} lazy={() => import(`./owner-pages/invitation`)} />
    <Route path={ownerRoutes.applications} lazy={() => import(`./owner-pages/applications`)}>
      {OWNER_APPLICATION_LIST_ACTIONS}
    </Route>
    <Route path={ownerRoutes.applicationDetails} lazy={() => import(`./owner-pages/application`)}>
      <Route
        path={ownerRoutes.applicationDetailsEdit}
        lazy={() => import(`./owner-pages/application/edit`)}
      />
      <Route
        path={ownerRoutes.applicationDetailsMove}
        lazy={() => import(`./owner-pages/application/move`)}
      />
      <Route
        path={ownerRoutes.applicationDetailsCancel}
        lazy={() => import(`./owner-pages/application/cancel`)}
      />
      <Route
        path={ownerRoutes.applicationDetailsReport}
        lazy={() => import(`./owner-pages/application/report`)}
      />
      <Route
        path={ownerRoutes.applicationOverviewLeaseDraftPreview}
        lazy={() => import('owner-pages/application/draft-preview')}
      />
      <Route
        path={ownerRoutes.applicationOverviewLeaseDraftCreate}
        lazy={() => import('owner-pages/application/draft-create')}
      />
    </Route>
    <Route path={ownerRoutes.leases} lazy={() => import('owner-pages/leases')}>
      {OWNER_LEASE_LIST_ACTIONS}
    </Route>
    <Route path={ownerRoutes.leaseDetails} lazy={() => import('owner-pages/lease')}>
      <Route errorElement={<ErrorSubpage />}>
        <Route index element={<Navigate to={ownerRoutes.leaseDetailsOverview} replace />} />
        <Route
          path={ownerRoutes.leaseDetailsOverview}
          lazy={() => import('owner-pages/lease/overview')}
        >
          {OWNER_LEASE_DETAILS_ACTIONS}
        </Route>
        <Route
          path={ownerRoutes.leaseDetailsChecklist}
          lazy={() => import('owner-pages/lease/checklist')}
        >
          <Route
            path={ownerRoutes.leaseDetailsEditChecklist}
            lazy={() => import('owner-pages/lease/checklist/edit-checklist-item')}
          />
          {OWNER_LEASE_DETAILS_ACTIONS}
        </Route>
        <Route
          path={ownerRoutes.leaseDetailsLedger}
          lazy={() => import('owner-pages/lease/ledger')}
        >
          <Route
            path={ownerRoutes.leaseDetailsLedgerCreateCharge}
            lazy={() => import('owner-pages/lease/ledger/create-charge')}
          />
          <Route
            path={ownerRoutes.leaseDetailsLedgerCreatePayment}
            lazy={() => import('owner-pages/lease/ledger/create-payment')}
          />
          <Route
            path={ownerRoutes.leaseDetailsLedgerEdit}
            lazy={() => import('owner-pages/lease/ledger/edit')}
          />
          <Route
            path={ownerRoutes.leaseDetailsLedgerDelete}
            lazy={() => import('owner-pages/lease/ledger/delete')}
          />
        </Route>
        <Route
          path={ownerRoutes.leaseDetailsInvoice}
          lazy={() => import('owner-pages/lease/invoice')}
        />
      </Route>
    </Route>
    <Route path={ownerRoutes.templates} lazy={() => import(`./owner-pages/templates`)}>
      <Route
        path={ownerRoutes.templatesCreate}
        lazy={() => import(`./owner-pages/templates/create`)}
      />
      <Route path={ownerRoutes.templatesEdit} lazy={() => import(`./owner-pages/templates/edit`)} />
      <Route
        path={ownerRoutes.templatesDelete}
        lazy={() => import(`./owner-pages/templates/delete`)}
      />
      <Route
        path={ownerRoutes.templatesViewPdf}
        lazy={() => import(`./owner-pages/templates/view-pdf`)}
      />
      <Route
        path={ownerRoutes.templatesClone}
        lazy={() => import(`./owner-pages/templates/clone`)}
      />
    </Route>
    <Route path={ownerRoutes.templateDetails} lazy={() => import(`./owner-pages/template`)}>
      <Route errorElement={<ErrorSubpage />}>
        <Route index element={<Navigate to={ownerRoutes.templateDetailsOverview} replace />} />
        <Route
          path={ownerRoutes.templateDetailsOverview}
          lazy={() => import(`./owner-pages/template/overview`)}
        >
          <Route
            path={ownerRoutes.templateDetailsOverviewEdit}
            lazy={() => import(`./owner-pages/template/overview/edit`)}
          />
          <Route
            path={ownerRoutes.templateDetailsOverviewViewPdf}
            lazy={() => import(`./owner-pages/template/overview/view-pdf`)}
          />
          <Route
            path={ownerRoutes.templateDetailsOverviewClone}
            lazy={() => import(`./owner-pages/template/overview/clone`)}
          />
          <Route
            path={ownerRoutes.templateDetailsOverviewDelete}
            lazy={() => import(`./owner-pages/template/overview/delete`)}
          />
        </Route>
        <Route
          path={ownerRoutes.templateDetailsEditor}
          lazy={() => import(`./owner-pages/template/editor`)}
        />
      </Route>
    </Route>

    <Route path={ownerRoutes.users} lazy={() => import('owner-pages/users')} />
    <Route path={ownerRoutes.userDetails} lazy={() => import('owner-pages/user')}>
      <Route errorElement={<ErrorSubpage />}>
        <Route index element={<Navigate to={ownerRoutes.userDetailsOverview} replace />} />
        <Route
          path={ownerRoutes.userDetailsOverview}
          lazy={() => import('owner-pages/user/overview')}
        >
          <Route
            path={ownerRoutes.userDetailsViewPhotoId}
            lazy={() => import('owner-pages/user/overview/view-photo-id')}
          />
          <Route
            path={ownerRoutes.userDetailsCreditScoreDownload}
            lazy={() => import('owner-pages/user/overview/credit-score-download-dialog')}
          />
        </Route>
        <Route
          path={ownerRoutes.userDetailsOwners}
          lazy={() => import('owner-pages/user/owners')}
        />
        <Route
          path={ownerRoutes.userDetailsLinkedAccounts}
          lazy={() => import('owner-pages/user/linked-accounts')}
        />
        <Route
          path={ownerRoutes.userDetailsAgentUnits}
          lazy={() => import('owner-pages/user/agent-units')}
        >
          <Route
            path={ownerRoutes.userDetailsAgentUnitsEdit}
            lazy={() => import('owner-pages/user/agent-units/edit')}
          />
          R
        </Route>
        <Route
          path={ownerRoutes.userDetailsApplications}
          lazy={() => import('owner-pages/user/applications')}
        >
          {OWNER_APPLICATION_LIST_ACTIONS}
        </Route>
        <Route path={ownerRoutes.userDetailsLeases} lazy={() => import('owner-pages/user/leases')}>
          {OWNER_LEASE_LIST_ACTIONS}
        </Route>

        <Route
          path={ownerRoutes.userDetailsDocuments}
          lazy={() => import('owner-pages/user/documents')}
        >
          <Route
            path={ownerRoutes.userDetailsDocumentsUpload}
            lazy={() => import('owner-pages/user/documents/upload')}
          />
          <Route
            path={ownerRoutes.userDetailsDocumentsView}
            lazy={() => import('owner-pages/user/documents/view-pdf')}
          />
          <Route
            path={ownerRoutes.userDetailsDocumentsEdit}
            lazy={() => import('owner-pages/user/documents/edit')}
          />
        </Route>
      </Route>
    </Route>
  </>
)

export const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<Root />}>
      <Route element={<Layout />}>
        <Route errorElement={<ErrorPage />}>
          <Route index element={<WelcomePage />} />
          <Route path={adminRoutes.login} lazy={() => import('pages/sign-in')} />
          <Route path={adminRoutes.signUp} lazy={() => import('pages/password-sign-up-dialog')} />
          <Route
            path={adminRoutes.passwordRecovery}
            lazy={() => import('pages/password-recovery-dialog')}
          />
          <Route
            path={adminRoutes.passwordReset}
            lazy={() => import('pages/password-reset-dialog')}
          />
          <Route element={<SignedInRoute out={adminRoutes.login} />}>
            <Route errorElement={<ErrorPage />}>
              <Route path={adminRoutes.communication} lazy={() => import('pages/communication')}>
                <Route
                  path={adminRoutes.communicationChat}
                  lazy={() => import('pages/communication/chat')}
                />
              </Route>
            </Route>
            <Route element={<NavigationContainer />}>
              <Route errorElement={<ErrorPage />}>
                <Route path={adminRoutes.account} lazy={() => import('pages/account')}>
                  <Route
                    path={adminRoutes.deleteAccount}
                    lazy={() => import('./pages/account/delete-account-dialog')}
                  />
                  <Route
                    path={adminRoutes.reloginAccount}
                    lazy={() => import('./pages/account/relogin-account-dialog')}
                  />
                  <Route
                    path={adminRoutes.accountSignatureEdit}
                    lazy={() => import('pages/account/signature-edit')}
                  >
                    <Route
                      index
                      element={<Navigate to={adminRoutes.accountSignatureEditDraw} replace />}
                    />
                    <Route
                      path={adminRoutes.accountSignatureEditDraw}
                      lazy={() => import('pages/account/signature-edit/draw')}
                    />
                    <Route
                      path={adminRoutes.accountSignatureEditType}
                      lazy={() => import('pages/account/signature-edit/type')}
                    />
                    <Route
                      path={adminRoutes.accountSignatureEditLoad}
                      lazy={() => import('pages/account/signature-edit/load')}
                    />
                  </Route>
                  <Route
                    path={adminRoutes.accountSignatureReset}
                    lazy={() => import('pages/account/signature-reset')}
                  />
                </Route>
                {ADMIN_PAGES}
                <Route path={ownerRoutes.my}>{OWNER_PAGES}</Route>
              </Route>
            </Route>
          </Route>
          <Route path={adminRoutes.forbidden} element={<ForbiddenPage />} />
        </Route>
        <Route path="*" element={<NotFoundPage />} />
      </Route>
    </Route>,
  ),
)
